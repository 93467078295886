import React, { useMemo } from "react";
import { BASE_COLUMN_TYPES, ColumnDataMap } from "../config/types";
import { CellProps } from "./Cell";
import { colors } from "@commonsku/styles";
import styled from "styled-components";
import { formatDateFromString } from "../../../../utils";

const Link = styled.a`
  display: block;
  align-content: center;
  color: ${colors.teal.main} !important;
`;

type TColumn = typeof BASE_COLUMN_TYPES.SalesTargetActivity;
const SalesTargetActivityCell = ({
  column,
  row,
  className,
  style = {},
}: CellProps<TColumn>) => {
  const { accessorKey, dataTransform } = column;
  const { date, details, id, type } = useMemo<ColumnDataMap<TColumn>>(() => {
    if (!dataTransform) {
      throw new Error("LinkCell requires dataTransform");
    }

    return { ...dataTransform(row.original[accessorKey]) };
  }, [accessorKey, dataTransform, row.original]);

  const typeToUrl =
    type && type === "SALES ORDER"
      ? "sales_order"
      : type
      ? type.toLowerCase()
      : "";

  const linkText =
    type && type === "SALES ORDER"
      ? "SO"
      : type
      ? type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
      : "";

  const formattedDate = formatDateFromString(date);

  return (
    <div className={className} style={style}>
      {!type ? (
        <span>--</span>
      ) : (
        <span>
          {type === "NOTE" || type === "MEETING" || type === "CALL" ? (
            `${linkText} `
          ) : (
            <Link
              href={`/${typeToUrl}.php?id=${id}`}
              target="_blank"
              rel="noreferrer"
            >
              {linkText} #{details}
            </Link>
          )}
          on {formattedDate}
        </span>
      )}
    </div>
  );
};

export default SalesTargetActivityCell;
